import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  IconButton,
  Badge,
  Typography,
  Box,
  FormControlLabel,
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import LoadingButton from "@mui/lab/LoadingButton";
import fetchUrl from "@/lib/fetchUrl";
import authHeaders from "@/lib/authHeaders";
import AutocompleteMultiselect from "@/pages/production/timeline/AutocompleteMultiselect";
import { productionDepartments } from "@/pages/production/timeline/ProductionDepartments";

const productionOrderStatuses = [
  { id: 0, text: "Pre Planned" },
  { id: 1, text: "Planned" },
  { id: 2, text: "In Progress" },
  { id: 3, text: "Released" },
  { id: 4, text: "Finished" },
];

const initialProdOrderStatuses = [
  productionOrderStatuses[1],
  productionOrderStatuses[2],
  productionOrderStatuses[3],
];

export const initialProdOrderFilters = {
  status: initialProdOrderStatuses,
  work_center: [],
  item_number: [],
  available: [{ id: "1", text: "Yes" }],
  production_order: [],
};

export const initialAllDepartmentItem = {
  id: 100,
  text: "All",
};

const ProductionTimelineFilters = ({ onFiltersChange, ...props }) => {
  const userData = JSON.parse(localStorage.getItem("msToken"));
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [loadingAllWorkCenters, setLoadingAllWorkCenters] = useState(false);
  const [internalFilters, setInternalFilters] = useState(
    initialProdOrderFilters,
  );

  const filtersApplied = (filtersObject) =>
    Object.values(filtersObject).some((e) => e.length > 0)
      ? filtersObject
      : false;

  const handleClose = () => {
    if (!loadingAllWorkCenters) {
      setFilterOpen(false);
    }
  };

  const loadAllWorkCenters = async (showAll) => {
    setLoadingAllWorkCenters(true);
    await fetch(
      fetchUrl(`${import.meta.env.VITE_VTS_API}/v1/select/work_center_number`, {
        per: 999,
      }),
      {
        headers: authHeaders(),
      },
    )
      .then((r) => r.json())
      .then((r) => {
        updateWorkCenterInDepartments(r.results);
        if (showAll) {
          showAllWorkCenters(r.results);
        }
      });
    setLoadingAllWorkCenters(false);
  };

  const filterWorkCenter = (workCenters, pattern) =>
    workCenters.filter((workCenter) => workCenter.id.startsWith(pattern));

  const updateWorkCenterInDepartments = (workCenters) => {
    productionDepartments.map((department) => {
      department.workCenter = filterWorkCenter(workCenters, department.pattern);
      return department;
    });
  };

  const showAllWorkCenters = (workCenters) => {
    const regex = /^O\d/;
    const validWorkCenters = workCenters.filter(
      (workCenter) => !regex.test(workCenter.id),
    );
    setInternalFilters((prev) => ({
      ...prev,
      work_center: validWorkCenters,
    }));
  };

  const handleFetchData = (url, term) =>
    fetch(fetchUrl(`${import.meta.env.VITE_VTS_API}/v1${url}`, { term }), {
      headers: authHeaders(),
    })
      .then((r) => r.json())
      .then(({ results }) => results);

  const handleSelectDepartment = (selectedItems) => {
    const hasAll = selectedItems.find((item) => item.text === "All");

    if (hasAll) {
      loadAllWorkCenters(true);
    } else {
      const workCenters = selectedItems.map((item) => item.workCenter).flat();
      setInternalFilters((prev) => ({
        ...prev,
        work_center: workCenters,
      }));
    }

    setSelectedDepartment(selectedItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentDepartment = productionDepartments.find(
        (department) => department.username === userData?.account.username,
      );
      if (currentDepartment) {
        await loadAllWorkCenters(false);
        handleSelectDepartment([currentDepartment]);
      } else {
        setSelectedDepartment([initialAllDepartmentItem]);
        await loadAllWorkCenters(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingAllWorkCenters) {
      onFiltersChange(internalFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(internalFilters)]);

  return (
    <>
      <IconButton onClick={() => setFilterOpen(true)}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!filtersApplied(internalFilters)}
        >
          <FilterListIcon />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={filterOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: 300 } }}
        {...props}
      >
        <Box display="flex" alignItems="center" m={2}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Filters
          </Typography>
          <LoadingButton onClick={handleClose} loading={loadingAllWorkCenters}>
            Apply
          </LoadingButton>
        </Box>
        {!loadingAllWorkCenters && (
          <Stack spacing={2} sx={{ px: 2 }}>
            <AutocompleteMultiselect
              size="small"
              options={[initialAllDepartmentItem, ...productionDepartments]}
              label="Departments"
              chipKey="text"
              valueKey="id"
              value={selectedDepartment}
              onChange={(v) => {
                handleSelectDepartment(v);
              }}
            />
            <AutocompleteMultiselect
              key={selectedDepartment}
              size="small"
              fetchOptions={(term) =>
                handleFetchData("/select/work_center_number", term, {
                  per: 999,
                })
              }
              label="Work Center"
              chipKey="id"
              valueKey="id"
              value={internalFilters.work_center}
              onChange={(v) => {
                setSelectedDepartment([]);
                setInternalFilters((prev) => ({ ...prev, work_center: v }));
              }}
            />
            <AutocompleteMultiselect
              size="small"
              fetchOptions={(term) =>
                handleFetchData("/select/production_order_number", term, {
                  per: 999,
                })
              }
              label="Production Order"
              chipKey="id"
              valueKey="id"
              value={internalFilters.production_order}
              onChange={(v) => {
                setInternalFilters((prev) => ({
                  ...prev,
                  production_order: v,
                }));
              }}
            />
            <AutocompleteMultiselect
              size="small"
              fetchOptions={(term) =>
                handleFetchData("/select/item_number", term)
              }
              label="Item Number"
              chipKey="id"
              valueKey="id"
              value={internalFilters.item_number}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, item_number: v }))
              }
            />
            <AutocompleteMultiselect
              size="small"
              label="Production Order Statuses"
              value={internalFilters.status}
              valueKey="id"
              options={productionOrderStatuses}
              onChange={(v) =>
                setInternalFilters((prev) => ({ ...prev, status: v }))
              }
            />

            <FormControl>
              <FormLabel>Available?</FormLabel>
              <RadioGroup
                row
                value={internalFilters?.available[0]?.id}
                onChange={(e) => {
                  setInternalFilters((prev) => ({
                    ...prev,
                    available: [{ id: e.target.value, text: e.target.label }],
                  }));
                }}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
      </Drawer>
    </>
  );
};

ProductionTimelineFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
};

export default ProductionTimelineFilters;
