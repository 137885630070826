import React, { useEffect, useState } from "react";
import moment from "moment";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import "@/pages/production/timeline/ProductionTimeline.css";
import { Fade, ListItemText, Box, Card, Tooltip } from "@mui/material";
import operationStyles from "@/components/operationStyles";
import authHeaders from "@/lib/authHeaders";
import fetchUrl from "@/lib/fetchUrl";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import ProductionTimelineFilters, {
  initialProdOrderFilters,
} from "@/pages/production/timeline/ProductionTimelineFilters";
import CircularProgress from "@mui/material/CircularProgress";
import humanizeSnakeCase from "@/lib/humanizeSnakeCase";

const ProductionTimeline = () => {
  const routingClasses = operationStyles();
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(initialProdOrderFilters);
  const [operations, setOperations] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, "M"));
  const appBarHeight = document.getElementById("app-bar")?.clientHeight || 64;

  const styleOperations = (items) =>
    items.map((op) => ({
      ...op,
      itemProps: {
        onDoubleClick: () =>
          window.open(
            fetchUrl("/production/operations", {
              prod_order: op.production_order,
              operation: op.operation,
            }),
            "_blank",
          ),
        className: routingClasses[op.routing_status.replace(" ", "_")],
      },
    }));

  const filterAvailable = (operations) => {
    switch (filters.available[0]?.id) {
      case 1:
        return operations.filter((o) => o.available);
      case 0:
        return operations.filter((o) => !o.available);
      default:
        return operations;
    }
  };

  const fetchData = () => {
    if (filters.work_center.length > 0) {
      setLoading(true);
      fetch(
        fetchUrl(
          `${import.meta.env.VITE_VTS_API}/v1/production/operations/list`,
          {
            machine_center: filters.work_center.map((e) => e.id),
            status: filters.status.map((e) => e.id),
            item_number: filters.item_number.map((e) => e.id),
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            available: filters.available[0]?.id ?? undefined,
            production_order: filters.production_order.map((e) => e.id),
          },
        ),
        {
          headers: authHeaders(),
        },
      )
        .then((r) => r.json())
        .then(({ operations }) => {
          setOperations(() => styleOperations(filterAvailable(operations)));
          setLoading(false);
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchData(), [startDate, endDate, JSON.stringify(filters)]);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading}>
        <ProductionTimelineFilters
          onFiltersChange={(newFilters) => setFilters(newFilters)}
        />
      </AppBarButtonWrapper>
      {filters.work_center.length > 0 ? (
        <Card variant="outlined">
          <Timeline
            style={{
              maxHeight: `calc(95vh - ${appBarHeight}px`,
              height: "100%",
            }}
            stickyHeader={true}
            defaultTimeStart={startDate}
            defaultTimeEnd={endDate}
            timeSteps={{
              second: 0,
              minute: 0,
              hour: 0,
              day: 1,
              month: 1,
              year: 1,
            }}
            groups={filters.work_center.map((e) => ({ ...e, title: e.text }))}
            items={operations}
            canResize={false}
            canChangeGroup={false}
            stackItems
            onBoundsChange={(canvasTimeStart, canvasTimeEnd) => {
              setStartDate(moment(canvasTimeStart));
              setEndDate(moment(canvasTimeEnd));
            }}
            sidebarWidth={250}
            onItemMove={(id, newTime) => {
              let operation = operations.find((op) => op.id === id);
              // TODO: change state before doing the fetching and rollback if the fetch was unsuccessful.
              fetch(
                fetchUrl(
                  `${
                    import.meta.env.VITE_VTS_API
                  }/v1/production/operations/change_date`,
                  {
                    production_order: operation.production_order,
                    operation: operation.operation,
                    date: moment(newTime).toISOString(),
                  },
                ),
                {
                  headers: authHeaders(),
                },
              ).then(() => fetchData());
            }}
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => {
              const { left: leftResizeProps, right: rightResizeProps } =
                getResizeProps();
              return (
                <div
                  {...getItemProps(item.itemProps)}
                  title="Double click to open operation"
                >
                  {itemContext.useResizeHandle ? (
                    <div {...leftResizeProps} />
                  ) : (
                    ""
                  )}
                  <Tooltip
                    title={
                      <div>
                        <div>{itemContext.title}</div>
                        {Object?.entries(item).map(([key, value]) => {
                          switch (key) {
                            case "start_time":
                            case "end_time":
                              return (
                                <div>
                                  {humanizeSnakeCase(key)}:{" "}
                                  {new Date(value).toISOString().split("T")[0]}
                                </div>
                              );
                            case "group":
                              return <div>Work center: {value}</div>;
                            case "routing_no":
                            case "routing_status":
                            case "status":
                            case "production_order":
                            case "operation":
                              return (
                                <div>
                                  {humanizeSnakeCase(key)}: {value}
                                </div>
                              );
                            case "available":
                            case "delayed":
                              return (
                                <div>
                                  {humanizeSnakeCase(key)}:{" "}
                                  {value ? "Yes" : "No"}
                                </div>
                              );
                            case "id":
                            case "title":
                            case "canMove":
                            default:
                              return null;
                          }
                        })}
                      </div>
                    }
                    placement="top"
                  >
                    <div
                      className="rct-item-content"
                      style={{
                        height: `${itemContext.dimensions.height}`,
                        overflow: "hidden",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {itemContext.title}
                      </span>
                    </div>
                  </Tooltip>

                  {itemContext.useResizeHandle ? (
                    <div {...rightResizeProps} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          >
            <TimelineMarkers>
              <TodayMarker>
                {({ styles }) => (
                  <Box
                    sx={{
                      ...styles,
                      backgroundColor: "primary.main",
                    }}
                  />
                )}
              </TodayMarker>
            </TimelineMarkers>
          </Timeline>
        </Card>
      ) : (
        <Fade in>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <ListItemText
              style={{ textAlign: "center" }}
              primary={
                !loading ? (
                  "No work center is selected"
                ) : (
                  <CircularProgress color="inherit" />
                )
              }
              secondary={
                !loading ? "Click the filter button at the top of the page" : ""
              }
            />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default ProductionTimeline;
